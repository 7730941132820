import type { Company } from '@temperworks/client-job-shift/src/components/Clients/Base/PlanningPage/types'
import { getAllPlanningPageData, SaveJobBody } from '../../../client-job-shift/src/services/PlanningPage'
import { defineStore } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, reactive, ref, watch, nextTick, unref, toRaw } from 'vue'
import { getShifts, saveJob } from '../../../client-job-shift/src/services/PlanningPage'
import { useCountryStore } from '@temperworks/spa/store/country'
import CreateShiftFormDataHelper from '../../../client-job-shift/src/composables/Clients/JobShift/CreateShiftFormDataHelper'

const showCreateShift = ref(false)
const isPendingPublishingShift = ref(false)

interface shiftsToPoll {
  jobKey: string,
  uuids: string[],
  dates: string[]
}

/**
 * Shifts uuids return from createBatchShifts to poll
 */
const shiftsToPoll = reactive<shiftsToPoll>({
  jobKey: '',
  uuids: [],
  dates: []
})


watch(showCreateShift, (showCreateShift) => {
  if(!showCreateShift) {
    CreateShiftFormDataHelper().resetState()
    window.scrollTo(0, 0)
  }
}, {
  immediate: true
})

export const usePlanningPageStore = defineStore('planning-page-store', () => {
  const router = useRouter()
  const route = useRoute()
  const countryStore = useCountryStore()

  /**
   * Router Date Query Params Handling
   */
  const dateTimeFormat = new Intl.DateTimeFormat('fr-CA')
  const selectedDay = ref<string>(dateTimeFormat.format(new Date()))
  const firstDayInWeek = ref<string>(dateTimeFormat.format(new Date()))
  const dateArray = ref<Array<string>>(getDateArray(firstDayInWeek.value))

  function getDateArray(firstDayInWeek: string): Array<string> {
    const newDateArray = []
    const startOfWeek = new Date(firstDayInWeek ? firstDayInWeek : new Date)
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay())

    for(let x = 0; x < 7; x++) {
      const tempDate = new Date(startOfWeek)
      tempDate.setDate(startOfWeek.getDate() + x + 1)
      const day = dateTimeFormat.format(tempDate)
      newDateArray.push(day)
    }

    return newDateArray
  }

  function setDate(day: string) {
    selectedDay.value = day
  }

  function getFirstDayOfWeek(date: Date): Date {
    const tempDate = new Date(date)
    // 0 (Sunday) to 6 (Saturday)
    const day = tempDate.getDay()
    // If Sunday, go back 6 days, else adjust to Monday
    const diff = day === 0 ? -6 : 1 - day
    tempDate.setDate(tempDate.getDate() + diff);
    return tempDate;
  }

  function setWeekData(newDay: Date){
    selectedDay.value = dateTimeFormat.format(new Date(newDay))
    const tempDate = new Date(newDay)
    firstDayInWeek.value = dateTimeFormat.format(getFirstDayOfWeek(tempDate))
    dateArray.value = getDateArray(firstDayInWeek.value)
  }

  function setSelectedDay(day: string) {
    selectedDay.value = day
  }

  function setWeek(newDay: Date) {
    setWeekData(newDay)
    router.push({ query: { on: dateArray.value[0] } })
  }

  function nextWeek() {
    if (isPendingShifts.value) {
      return
    }

    isPendingShifts.value = true
    const tempDate = new Date(firstDayInWeek.value);
    firstDayInWeek.value = dateTimeFormat.format(new Date(tempDate.setDate(tempDate.getDate() + 7)))
    dateArray.value = getDateArray(firstDayInWeek.value)
    setDate(dateArray.value[0])
    router.push({ query: { on: dateArray.value[0] } })
  }

  function previousWeek() {
    if (isPendingShifts.value) {
      return
    }

    isPendingShifts.value = true
    const tempDate = new Date(firstDayInWeek.value);
    firstDayInWeek.value = dateTimeFormat.format(new Date(tempDate.setDate(tempDate.getDate() - 7)))
    dateArray.value = getDateArray(firstDayInWeek.value)
    setDate(dateArray.value[0])
    router.push({ query: { on: dateArray.value[0] } })
  }

  /**
   * Planning Page Data
   */
  const companies = ref<Array<Company>>([])
  const companiesHistory = ref(0)

  const fetchApi = inject('fetchApi') as Function
  const isPendingShifts = ref<boolean>(false)
  const isPendingPage = ref<boolean>(false)

  const totalAmountOfShifts = computed(() => {
    return companies.value.flatMap(company => company.projects.flatMap(project => project.jobs.flatMap(job => job.shifts))).length
  })

  function setShiftsToCompanies (shifts, companiesData) {
    return companiesData.map((company) => {
      company.projects = company.projects.map((project) => {
        project.jobs = project.jobs.map((job) => {

          job.shifts = job.shifts && [
            ...job?.shifts.filter(existingShift =>
              !shifts.some(newShift => newShift.job_key === existingShift.job_key)
            ),
            ...shifts.filter(shift => shift.job_key === job.key)
          ];

          return job
        })
        return project
      })
      return company
    })
  }

  function setShifts(shiftsData, companiesValue = companies.value) {
    let companiesData = toRaw(unref(companiesValue))
    companies.value = []
    companies.value = setShiftsToCompanies(shiftsData, companiesData)
    companiesHistory.value++
  }


  function mapJobToCompany(jobData, companiesValue){
    return companiesValue.reduce((acc, cv) => {
      let projectIndex = cv.projects.findIndex((project) => project.key === jobData.project_id)
      if(projectIndex >= 0) {
        cv.projects[projectIndex].jobs.push({
          key: jobData.id,
          project: {
            id: jobData.project_id,
            key: jobData.id,
          },
          title: jobData.title,
          briefing: jobData.briefing,
          dress_code: jobData.dress_code,
          tips: jobData.tips,
          slug: jobData.slug,
          reference: jobData.reference,
          shifts: []
        })
      }
      acc.push(cv)
      return acc
    }, [])
  }

  function setJob(jobData, companiesValue = companies.value) {
    let jobValue = toRaw(unref(jobData))
    companies.value = []
    companies.value = mapJobToCompany(jobValue, companiesValue)
    companiesHistory.value++
  }


  async function createJob(locationId: string, body:SaveJobBody, fetchApiFn = fetchApi, countryStoreBackend = countryStore.backend) {
    try {
      const savedJobData = await saveJob(fetchApiFn, countryStoreBackend, locationId, body)
      setJob(savedJobData)
      return savedJobData.id
    } catch(error) {
      console.error("Error loading jobs", error)
      throw error
    }
  }

  async function initPlanningPageData() {
    try {
      isPendingPage.value = true
      companies.value = await getAllPlanningPageData(fetchApi, dateArray.value[0], dateArray.value[6], countryStore.backend)
      companiesHistory.value++
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      isPendingPage.value = false
    }
  }

  async function getShiftByDates(fetchApiFn = fetchApi, startDate = dateArray.value[0], endDate = dateArray.value[6], countryStoreBackend = countryStore.backend) {
    return await getShifts(fetchApiFn, startDate, endDate, countryStoreBackend)
  }

  async function loadShifts() {
    try {
      const shiftsData = await getShiftByDates()
      setShifts(shiftsData)
    } catch(error) {
      console.error("Error loading shifts", error)
      throw error
    } finally {
      await nextTick(() => {
        isPendingShifts.value = false
      })
    }
  }


  /**
   * Shift polling functionality for status notifications
   */
  const shiftToPollDateRange = computed(() => {
    if(shiftsToPoll.dates.length === 0) return

    const startDate = new Date(Math.min(...shiftsToPoll.dates.map(date => new Date(date).getTime())))
    const endDate = new Date(Math.max(...shiftsToPoll.dates.map(date => new Date(date).getTime())))
    const startDateFormatted =  startDate.toISOString().split('T')[0]
    const endDateFormatted =  endDate.toISOString().split('T')[0]

    return {
      startDate: startDateFormatted,
      endDate: endDateFormatted
    }
  })

  watch(() => shiftsToPoll.uuids, (shiftsToPollUuids) => {
    if(shiftsToPollUuids.length === 0) {
      shiftsToPoll.jobKey = ''
      isPendingPublishingShift.value = false
    }
  })

  function setShiftsUuidsToPoll(uuids:string[]){
    shiftsToPoll.uuids = uuids
  }

  function removeShiftUuidFromPoll(uuidToRemove:string) {
    shiftsToPoll.uuids = shiftsToPoll.uuids.filter(uuid => uuid !== uuidToRemove)
  }

  function updateShiftDatesToPoll(dates: string[]) {
    shiftsToPoll.dates = dates
  }

  function resetShiftsToPoll() {
    shiftsToPoll.jobKey = ''
    shiftsToPoll.uuids = []
    shiftsToPoll.dates = []
  }

  async function onSetShowCreateShift(setShowValue: boolean, navigate: boolean = false, query?: {
    on: string,
    jobKey?: string,
    projectKey?: string,
    projectId?: string
  }) {

    let to = {
      path: '/dashboard/planning' + (setShowValue ? '/create-shift' : ''),
      query: {
        on: query?.on ?? route.query.on,
        jobKey: query?.jobKey,
        projectKey: query?.projectKey,
        projectId: query?.projectId
      }
    }

    try {
      if(navigate) {
        await router.push(to)
      }
      showCreateShift.value = setShowValue
    } catch(err) {
      console.error("Error navigating to create shift", err)
      throw err
    }

  }


  return {
    companies,
    companiesHistory,
    createJob,
    getShiftByDates,
    setShifts,
    loadShifts,
    totalAmountOfShifts,
    isPendingShifts,
    isPendingPage,
    dateTimeFormat,
    selectedDay,
    setSelectedDay,
    firstDayInWeek,
    dateArray,
    nextWeek,
    previousWeek,
    setWeek,
    setWeekData,
    setDate,
    onSetShowCreateShift,
    showCreateShift,
    shiftsToPoll,
    setShiftsUuidsToPoll,
    removeShiftUuidFromPoll,
    updateShiftDatesToPoll,
    resetShiftsToPoll,
    shiftToPollDateRange,
    isPendingPublishingShift,
    initPlanningPageData
  }
})

import { useCountryStore } from '../store/country'
import temperCountries from '../composables/temperCountries'


export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('setCountry', async (route:any) => {
    const countryStore = useCountryStore()
    const cookie = document.cookie.match(RegExp('(?:^|;\\s*)' + 'country' + '=([^;]*)'))
    const countryArray = temperCountries().temperCountries
    if (cookie) {
      const countryCheck = countryArray.find(countryCheck => countryCheck.country === cookie[1])
      if (countryCheck) {
        countryStore.setCountry(countryCheck.country)
        countryStore.setBackend(countryCheck.backend)
        countryStore.setCurrency(countryCheck.currency)
        await countryStore.setLocale(countryCheck.locale)
        await nuxtApp.$i18n.setLocale(countryCheck.locale)
      }
    }
  })
})

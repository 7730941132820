import { Company, Job, Shift, CollapsedType } from '../components/Clients/Base/PlanningPage/types'

export async function getAllPlanningPageData(fetchApi: Function, startDate: string, endDate: string, backend: string) {
  return Promise.all([getCompanies(fetchApi, backend), getAllJobs(fetchApi, backend), getShifts(fetchApi, startDate, endDate, backend)])
    .then(([companiesResponse, jobs, shifts]) => {
      return companiesResponse.map((company) => {
        company.projects = company.projects.map((project) => {
          project.jobs = jobs.filter((job) => job.project.key === project.key)
          project.jobs = project.jobs.map((job) => {
            job.shifts = shifts.filter((shift) => shift.job_key === job.key)
            return job
          }).sort((a, b) => sortAlphaByKey(a, b, 'title'))
          return project
        }).sort((a, b) => sortAlphaByKey(a, b, 'name'))
        return company
      }).sort((a, b) => sortAlphaByKey(a, b, 'name'))
    })
}

/**
 * Get all shifts between start and end date
 * @param start
 * @param end
 */
export async function getShifts(fetchApi: Function, start: string, end: string, backend: string): Promise<Shift[]> {
  const response = await fetchApi(`/api/v4/shifts?start=${start}&end=${end}`, backend)
  const data = await response.json()
  return data.data ?? []
}

/**
 * Gets jobs based on key
 * @param lastKey
 */
async function getJobs(fetchApi: Function, nextUrl: string, backend?: string) {
  const response = await fetchApi(nextUrl, backend || '')
  const data = await response.json()
  return data
}

/**
 * Call jobs endpoint repeatedly until we have all jobs
 */
export async function getAllJobs(fetchApi: Function, backend: string): Promise<Job[]> {
  const jobs = await getJobs(fetchApi, '/api/v4/jobs', backend);
  while (jobs?.meta?.next_url) {
    const nextJobs = await getJobs(fetchApi, jobs.meta.next_url);
    const { meta: nextMeta, data: nextData } = nextJobs
    jobs.meta.next_url = nextMeta.next_url;
    jobs.data = [...jobs.data, ...nextData]
  }
  return jobs.data ?? []
}

/**
 * Get single job by project id
 */
export async function getJob(fetchApi: Function, backend: string, projectId: string, jobId: string): Promise<Job> {
  const response = await fetchApi(`/api/v4/clients/${projectId}/job/${jobId}`, backend)
  return await response.json()
}

/**
 * Save single job
 */
export interface SaveJobBody {
  title: string,
  briefing: string,
  skills: number[],
  appearances: number[],
  tips: boolean,
  report_to_name: string,
  report_to_phone: string,
  communication_to_users: string[],
  category_id: string,
  report_at_address_line1: string,
  report_at_address_line2: string,
  report_at_address_line3: string,
  report_at_address_zip_code: string,
  report_at_address_country: string,
  report_at_address_city: string,
  project_id: string,
  project_uuid: string,
}
export async function saveJob(fetchApi: Function, backend: string, locationId: string, body: SaveJobBody): Promise<Job> {
  const response = await fetchApi(`/api/v4/clients/${locationId}/job`, backend, {
    method: 'POST',
    body: JSON.stringify(body)
  })
  return await response.json()
}


/**
 * sort array alphabetically by key property
 * @param a
 * @param b
 */
function sortAlphaByKey(a: any, b: any, key: string) {
  if (a?.[key]?.toLowerCase() < b?.[key]?.toLowerCase()) return -1
  if (a?.[key]?.toLowerCase() > b?.[key]?.toLowerCase()) return 1
  return 0;
}


/**
 * Gets clients and projects
 */
async function getCompanies(fetchApi: Function, backend: string): Promise<Company[]> {
  const response = await fetchApi(
    '/api/v4/clients:with-projects',
    backend
  )
  const data = await response.json()
  return data.data ?? []
}

export async function getPreferences(fetchApi: Function, userId: string, backend: string) {
  const response = await fetchApi(`/api/v3/user/${userId}/planning-tool-preferences`, backend)
  const data = await response.json()
  return data.data
}

export function updatePreferences(fetchApi: Function, userId: string, shiftStatusses: any, backend: string) {
  fetchApi(`/api/v3/user/${userId}/planning-tool-preferences`, backend, {
    method: 'PATCH',
    body: JSON.stringify({
      clients: [],
      day_view: 7,
      shift_statuses: shiftStatusses,
    }),
  })
}

export async function updateCollapsed(fetchApi: Function, userId: string, type: CollapsedType, method: 'DELETE' | 'POST', id: string, backend: string) {
  fetchApi(`/api/v2/users/${userId}/collapsed-${type}/${id}`, backend, {
    method,
  })
}

import { reactive, ref } from 'vue'
import { ShiftCheckOutManager, UserWithRole } from '@temperworks/types'

const initialFormDataShift = {
  positions: 1,
  earnings_per_hour: 0,
  variable_pricing: false,
  cancellation_policy: 48,
  job_id: '',
  category: {
    id: '',
    description: ''
  },
  flexpools: [],
  flexible: false,
  start_time_earlier_variation: 0,
  start_time_later_variation: 0,
  end_time_earlier_variation: 0,
  end_time_later_variation: 0,
  auto_accept_recent_freelancers: false,
  ends_at: '',
  starts_at: '',
  shiftDates: [] as Array<string | undefined>,
  startTime: '08:00',
  endTime: '15:00',
}

export interface SelectedLocation {
  id: string
  clientId: string,
  projectId: string,
  locationId: string,
  extraValue: string,
  name: string,
  isMissingDetails: boolean,
  credit_status: string
}

const initialSelectedLocation:SelectedLocation = {
  clientId: '',
  credit_status: '',
  extraValue: '',
  id: '',
  locationId: '',
  projectId: '',
  name: '',
  isMissingDetails: false
}

export interface SelectedJob {
  id: string
  name: string,
  extraValue?: string,
  locationId?: string,
  projectId?: string,
  report_at_address?: {
    line1: string,
    line2: string,
    line3 : string,
    city : string,
    zip_code: string
  },
}

const initialSelectedJob: SelectedJob = {
  id: '',
  name: '',
  extraValue: '',
  locationId: '',
  projectId: '',
  report_at_address: {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    zip_code: ''
  }
}


function resetSelectedLocation() {
  Object.assign(selectedLocation, initialSelectedLocation)
}

function resetSelectedJob() {
  Object.assign(selectedJob, initialSelectedJob)
}

function setSelectedJob(job:SelectedJob) {
  resetSelectedJob()
  Object.assign(selectedJob, job)
}

function setSelectedLocation(location:any) {
  resetSelectedLocation()
  Object.assign(selectedLocation, location)
}


function setMinRate(rate: number) {
  minimumRate.value = rate
}

function setMaxRate(rate: number) {
  maximumRate.value = rate
}

function setRates( rates: { min?:number , max?:number }){

  if(rates?.min) {
    setMinRate(rates.min)
  }

  if(rates?.max) {
    setMaxRate(rates.max)
  }

}

function setReferenceShiftId(id: string) {
  reference_shift_id.value = id
}


const initialSelectedJobDetails = {
  id: '',
  title: '',
  category: {
    id: '',
    description: ''
  },
  appearances: [],
  communication_to_users: [],
  briefing: '',
  report_at_address_line1: '',
  report_at_address_line2: '',
  report_at_address_line3: '',
  report_at_address_city: '',
  report_at_address_zip_code: '',
  report_at_address_country: '',
  skills: [],
  tips: 'n/a',
  report_to_phone: '',
  report_to_name: '',
  reverse_charged_vat_enabled: '',
}

const selectedJobDetails = reactive(initialSelectedJobDetails)

function resetSelectedJobDetails() {
  Object.assign(selectedJobDetails, initialSelectedJobDetails)
}

function setJobDetails(data: any) {
  Object.assign(selectedJobDetails, {
    id: data.id,
    title: data.title,
    category: {
      id: data.category?.id,
      description: data.category?.description
    },
    appearances: data.appearances,
    communication_to_users: data.communication_to_users,
    briefing: data.briefing,
    report_at_address_line1: data.report_at_address?.line1,
    report_at_address_line2: data.report_at_address?.line2,
    report_at_address_line3: data.report_at_address?.line3,
    report_at_address_city: data.report_at_address?.city,
    report_at_address_zip_code: data.report_at_address?.zip_code,
    report_at_address_country: data.report_at_address?.country,
    skills: data?.skills || [],
    tips: data?.tips === null ? 'null' : data?.tips ?? 'n/a',
    report_to_phone: data?.report_to_phone?.number,
    report_to_name: data.report_to_name,
    reverse_charged_vat_enabled: data.reverse_charged_vat_enabled,
  })
}

function resetShiftSettings(){
    formDataShift.positions = 1;
    formDataShift.earnings_per_hour = 0
    formDataShift.cancellation_policy = 48
}

function resetFormDataShiftDate(){
  formDataShift.startTime = '08:00'
  formDataShift.endTime = '15:00'
}

function resetFormDataShiftFlexTimes(){
  formDataShift.flexible = false
  formDataShift.start_time_earlier_variation = 0;
  formDataShift.start_time_later_variation = 0;
  formDataShift.end_time_earlier_variation = 0;
  formDataShift.end_time_later_variation = 0;
}

function setFormDataShift(data: any) {

  const { min_earning_per_hour, category, recent_shift: {
    flexpools,
    shift: {
      start_time,
      end_time,
      enable_auto_accept_recent_freelancers,
      variable_pricing,
      positions,
      earnings_per_hour,
      cancellation_policy
    }
  } } = data

  formDataShift.category.id = category.id
  formDataShift.category.description = category.description
  formDataShift.earnings_per_hour = earnings_per_hour < min_earning_per_hour ? min_earning_per_hour : earnings_per_hour
  formDataShift.startTime = start_time || '08:00'
  formDataShift.endTime = end_time || '15:00'
  formDataShift.auto_accept_recent_freelancers = enable_auto_accept_recent_freelancers
  formDataShift.variable_pricing = variable_pricing
  formDataShift.positions = !positions ? 1 : positions
  formDataShift.cancellation_policy = cancellation_policy ?? formDataShift.cancellation_policy
  formDataShift.flexpools = flexpools ? flexpools.data.map((flexpool: any) => flexpool.id) : formDataShift.flexpools
}


const formDataShift = reactive({ ...initialFormDataShift })
const jobItems = ref([])
let selectedLocation = reactive({ ...initialSelectedLocation })
let selectedJob = reactive({ ...initialSelectedJob })
const averageRate = ref(0)
const minimumRate = ref(15.0)
const maximumRate = ref(1000.0)
const supervisors = ref<Array<ShiftCheckOutManager>>([])
const planners = ref<Array<UserWithRole>>([])
const nonPlanners = ref<Array<UserWithRole>>([])
const lastMinuteShift = ref(false)
const smartActions = ref(false)
const reference_shift_id = ref('')
const refetchUsers = ref(false)


function resetState() {
  resetFormDataShiftDate()
  jobItems.value = []
  resetSelectedLocation()
  resetSelectedJob()
  resetSelectedJobDetails()
  averageRate.value = 0
  minimumRate.value = 15.0
  maximumRate.value = 1000.0
  supervisors.value = []
  planners.value = []
  nonPlanners.value = []
  lastMinuteShift.value = false
  smartActions.value = false
  reference_shift_id.value = ''
  refetchUsers.value = false
}

export default (backend?: string, id?: string, fetchApi?: any, clients?: any, user?: any) => {
  return {
    formDataShift,
    resetFormDataShiftDate,
    resetShiftSettings,
    resetFormDataShiftFlexTimes,
    jobItems,
    selectedLocation,
    setSelectedLocation,
    selectedJob,
    selectedJobDetails,
    setSelectedJob,
    setRates,
    setReferenceShiftId,
    setFormDataShift,
    setJobDetails,
    minimumRate,
    maximumRate,
    supervisors,
    planners,
    nonPlanners,
    averageRate,
    lastMinuteShift,
    reference_shift_id,
    refetchUsers,
    smartActions,
    resetState
  }
}

import mixpanel from 'mixpanel-browser'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  if(import.meta.env.PROD) {
    mixpanel.init(config.public.MIXPANEL_TOKEN, {
      debug: process.env.NODE_ENV !== 'production',
      track_pageview: false,
      persistence: 'localStorage'
    })
  }

  return {
    provide: {
      mixpanel: mixpanel
    }
  }
})
